import itehad_mullak from '../icons/itehad_mullak.png'
import aqarat_mushtarakah from '../icons/aqarat_mushtarakah.png'
import thseel from '../icons/thseel.png'
import siana from '../icons/siana.png'
import motabaah from '../icons/motabaah.png'
import tntheef from '../icons/tntheef.png'
import social_media from '../icons/social_media.png'
import Amlak_edara from '../icons/Amlak_edara.png'
import Aqaar_edara from '../icons/Aqaar_edara.png'
import Towtheeq from '../icons/Towtheeq.png'
import marketing from '../icons/marketing.png'
import thseel_Ejar from '../icons/Thsees_Ejar.png'
import ownership from '../icons/ownership.png'
import Tajeer from '../icons/Tajeer.png'
import land_selling from '../icons/land_selling.png'
import our_Projects01 from '../images/our_Projects01.jpeg'
import our_Projects02 from '../images/our_Projects02.jpeg'
import linkedin from '../icons/linkedin.png'
import tiktok from '../icons/tiktok.png'
import telegram from '../icons/telegram.png'
import youtube from '../icons/youtube.png'
import whatsapp from '../icons/whatsapp.png'
import instagram from '../icons/instagram.png'
import facebook from '../icons/facebook.png'
import twitter from '../icons/twitter-sign.png'




const Data = [
    {
        title: "الرئيسية",
        link: "Home"
    },
    {
        title: "من نحن؟",
        link: "AboutUs"
    },
    {
        title: "لماذا تختارنا؟",
        link: "whyUs"
    }
]
const ServicesDataMullak = [
    {
        icon: itehad_mullak,
        header: "تنظيم جمعية إتحاد ملاك"
    },
    {
        icon: aqarat_mushtarakah,
        header: "إدارة ومتابعة العقارات المشتركة"
    },
    {
        icon: thseel,
        header: "تحصيل رسوم الإشتراك"
    }, {
        icon: siana,
        header: "متابعة أعمال الصيانة",
        explain: "( سباكة - كهرباء - مصاعد )"
    },
    {
        icon: motabaah,
        header: "متابعة الفواتير وسدادها"
    },
    {
        icon: tntheef,
        header: "متابعة نظافة المباني وتنظيم المواقف"
    },
    {
        icon: social_media,
        header: "إدارة ومتابعة العقارات المشتركة"
    }
]

const ServicesDataEjar = [
    {
        icon: Amlak_edara,
        header: "إدارة الأملاك"
    },
    {
        icon: Aqaar_edara,
        header: "إدارة العقارات نيابة عن الملاك"
    },
    {
        icon: Towtheeq,
        header: "توثيق عقود الإيجار"
    }, {
        icon: marketing,
        header: "تسويق العقارات عبر المنصات",
    },
    {
        icon: thseel_Ejar,
        header: "تحصيل الإيجارات"
    },
    {
        icon: ownership,
        header: "إستلام وتسليم الوحدات نيابة عن المالك"
    },
    {
        icon: Tajeer,
        header: "تأجير عقارات بالكامل"
    },
    {
        icon: land_selling,
        header: "بيع وشراء الأراضي والعقارات"
    }
]

const OurProjectsData = [
    {
        image: our_Projects01,
        title: "مشروع الردسي مول",
        text: "هو أحد المشاريع التي تديرها شركة أديرا لخدمات الأعمال، منذ عام 2015 وحتى الآن، نقوم بتقديم خدمات مميزة وحصرية لشركة الردسي مول",
    },
    {
        image: our_Projects02,
        title: "مشروع مجمع العرب",
        text: "هو أحد المشاريع التي تديرها شركة أديرا لخدمات الأعمال، منذ عام 2012، وحتى الآن، نقوم بتقديم خدمات إحترافية، وبجودات عالية"
    },
]

const footerSocialMediaData = [
    {
        icon: linkedin,
        link: "https://www.google.com/"
    },
    {
        icon: tiktok,
        link: "https://www.google.com/"
    },
    {
        icon: telegram,
        link: "https://www.google.com/"
    },
    {
        icon: youtube,
        link: "https://www.google.com/"
    },
    {
        icon: whatsapp,
        link: "https://www.google.com/"
    },
    {
        icon: instagram,
        link: "https://www.google.com/"
    },
    {
        icon: facebook,
        link: "https://www.google.com/"
    },
    {
        icon: twitter,
        link: "https://www.google.com/"
    },
]

export { Data, ServicesDataMullak, ServicesDataEjar, OurProjectsData, footerSocialMediaData }