import Our_Mission from '../images/aboutImg01.jpeg'
import Our_Vision from '../images/aboutImg02.jpg'
import Our_Values from '../images/aboutImg03.jpeg'
import mission_icon from '../icons/mission_icon.png'
import vision_icon from '../icons/vision_icon.png'
import values_icon from '../icons/values_icon.jpg'
import quality from '../icons/value_01.png'
import Professional_ethics from '../icons/value_02.png'
import Accessibility from '../icons/value_03.png'
import Efficiency_and_effectiveness from '../icons/value_04.png'
import Being_customer_oriented from '../icons/value_05.png'
import Community_partnership from '../icons/value_06.png'
import Learning_and_development from '../icons/value_07.png'


function AboutUs() {
    return (
        <>
            <section id='AboutUs'>
                <div className=' flex  justify-center mb-4'>
                    <h1 className=" text-white bg-gradient-to-bl from-main from-70% rounded-lg px-6 py-2 font-black text-4xl ">من نحن؟</h1>
                </div>
                {/* Our Mission Card */}
                <div className='mx-20 rounded-3xl flex flex-col justify-center bg-main mb-4'>
                    <div className='flex justify-start items-center'>
                        <img className='w-[90rem] h-[25rem] rounded-3xl' src={Our_Mission} />
                        <div className='flex flex-col items-end mx-6 w-full '>
                            <div className='flex justify-start items-center  text-white'>
                                <img className='h-36 ' src={mission_icon} />
                                <h1 className=' text-end text-5xl font-bold '>رسالتنا</h1>
                            </div>
                            <h1 className='text-white text-right'>هنا يتم كتابة الرسالة التي تطمح أديرا بتحقيقها من خلال الشركة،، هنا يتم كتابة الرسالة التي تطمح أديرا بتحقيقها من خلال الشركة. ،، هنا يتم كتابة الرسالة التي تطمح أديرا بتحقيقها من خلال الشركة.</h1>
                        </div>
                    </div>
                </div>

                {/* Our Vision Card */}
                <div className='mx-20 rounded-3xl flex flex-col justify-center bg-main mb-4'>
                    <div className='flex justify-start items-center'>
                        <div className='flex flex-col items-center mx-6 w-full '>
                            <div className='flex justify-center items-center gap-10'>
                                <img className='h-36' src={vision_icon} />
                                <h1 className='text-5xl text-white font-bold'>رؤيتنا</h1>
                            </div>
                            <h1 className='text-white text-right'>هنا يتم كتابة الرؤية الخاصة بشركة أديرا لخدمات الأعمال،،،هنا يتم كتابة الرؤية الخاصة بشركة أديرا لخدمات الأعمال،،،،
                                هنا يتم كتابة الرؤية الخاصة بشركة أديرا لخدمات الأعمال</h1>
                        </div>
                        <img className='w-[90rem] h-[25rem] rounded-3xl' src={Our_Vision} />
                    </div>
                </div>
                {/* Our Values Card */}
                <div className='mx-20 rounded-3xl flex flex-col justify-center bg-main mb-4'>
                    <div className='flex justify-start items-center'>
                        <img className='w-[90rem] h-[25rem] rounded-3xl' src={Our_Values} />
                        <div className='flex flex-col items-end mx-6 w-full '>
                            <div className='flex justify-start items-center text-white'>
                                <img className='h-36' src={values_icon} />
                                <h1 className='text-end text-5xl font-bold'> قيمنا </h1>
                            </div>
                            <h1 className='text-white text-right'>تتمثل قيمنا في ثلاثة محاور رئيسية، وتعتبر هي النهج الذي نتبعه في كافة معاملاتنا، ولهذا يتم إختيارنا</h1>

                        </div>
                    </div>
                </div>
                <hr className='bg-gray-300 w-full h-0.5 my-10' />
            </section>
        </>
    );
}

export default AboutUs