import quality from '../icons/quality.png'
import honesty from '../icons/honesty.png'
import Credibility from '../icons/Credibility.png'

function WhySelectingUs() {
    return (
        <>
            <div className=' flex   justify-center'>
                <h1 className=" text-white bg-gradient-to-bl from-main from-70% rounded-lg px-4 py-2 font-black text-4xl ">لماذا تختارنا؟</h1>
            </div>
            <h1 className="text-center text-main my-4">!!! نحن نعدكم بأننا كنا،، وما زلنا،، وسنظل،، ملتزمون بالتمسك بنهجنا وبالقيم التي نعدكم بها</h1>
            <div className="flex flex-row justify-center text-center gap-8">

                <div className="p-4 text-white h-56 w-72 rounded-3xl bg-main flex flex-col items-center">
                    <img className=' h-16 w-16 mt-2' src={Credibility} />
                    <h1 className=' font-black text-4xl'>المصداقية</h1>
                    <p className='mt-2  '>نهجنا هو أن نقوم ما نعمل، ولا نقول لعملائنا إلا ما نستطيع فعله</p>
                </div>

                <div className="p-4 text-main h-56 w-72 rounded-3xl bg-[#D9D9D9] flex flex-col items-center">
                    <img className='h-16 w-16 mt-2' src={honesty} />
                    <h1 className='font-black text-4xl'>الأمانة</h1>
                    <p className='mt-2   '>يعي عملائنا، أن ما نقوم به من أجلهم يتطلب الكثير من الصدق والأمانة</p>
                </div>

                <div className="p-4 text-white h-56 w-72 rounded-3xl bg-main flex flex-col items-center">
                    <img className='h-16 w-16 mt-2' src={quality} />
                    <h1 className='font-black text-4xl'>الجودة</h1>
                    <p className='mt-2 '>نقوم بتقديم خدماتنا على أكمل <br /> وجه يرضينا ويرضي كافة عملائنا</p>
                </div>

            </div>
            <hr className='my-10' />

        </>
    );
}

export default WhySelectingUs;