import phone from '../icons/phone-call.png'
import location from '../icons/location.png'
import email from '../icons/email.png'

import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function ContactUs() {

    const [isRadioSelected, setIsRadioSelected] = useState(false);
    const [isRadioSelected2, setIsRadioSelected2] = useState(false);
    const [isRadioSelected3, setIsRadioSelected3] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        // emailjs.sendForm('service_ovkwq1e', 'template_2xmv7j5', form.current, 'ua95kkyJoGrecmu7u')
        emailjs.sendForm('service_49bth7k', 'template_2x4vpqm', form.current, 'ua95kkyJoGrecmu7u')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    };

    return (
        <section id="contactUs">
            <div>
                <div className=' flex   justify-center'>
                    <h1 className=" text-white bg-gradient-to-bl from-main from-70% rounded-lg px-6 py-2 font-black text-4xl ">تواصل معنا</h1>
                </div>
                <h1 className="text-center text-main my-4">لا تتردد في التواصل معنا في أي وقت</h1>

                <div className="flex justify-center text-white rounded-xl gap-8 my-10 ">

                    <div className=' group flex flex-col items-center justify-center  bg-main w-96 h-48 rounded-xl overflow-visible '>
                        <img src={phone} alt="" className="w-32 h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16  border-4 border-main rounded-full" />
                        <h1 className="text-center ">+966 55 336 6865</h1>
                    </div>

                    <div className='group flex flex-col items-center justify-center  bg-[#D9D9D9] w-96 rounded-xl overflow-visible '>
                        <img src={email} alt="" className="w-32 h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16  border-4 border-[#D9D9D9] rounded-full" />
                        <h1 className="text-center text-main">info@adera.com.sa</h1>
                    </div>

                    <div className='group flex flex-col items-center justify-center  bg-main w-96 rounded-xl overflow-visible '>
                        <img src={location} alt="" className="w-32 h-32 p-4 bg-white -translate-y-8 group-hover:-translate-y-16  border-4 border-main rounded-full" />
                        <h1 className="text-center">جدة، حي الصفا، الأمير ماجد</h1>
                    </div>
                </div>

                <div className="grid grid-cols-2 justify-center gap-8 px-10">
                    <iframe title='Location' className="rounded-xl col-span-1 w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3710.432407370369!2d39.19834587458649!3d21.569038169004315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d195c7049521%3A0xa14255f0eb2bbc5d!2z2KfYr9mK2LHYpyDZhNiu2K_Zhdin2Kog2KfZhNin2LnZhdin2YQgLSDYudmC2KfYsSDYrtiv2YXYp9iqINi52KfZhdmH!5e0!3m2!1sen!2ssa!4v1700042010439!5m2!1sen!2ssa"
                        width="500" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <form ref={form} onSubmit={sendEmail} className="flex flex-col col-span-1 w-full items-center gap-4">
                        <input required className=" outline-none focus:border-main text-main border-2 pr-2 rounded-md w-full h-12 text-right" placeholder="الإسم الكامل" name="from_name" />
                        <input className=" outline-none focus:border-main text-main border-2 pr-2 rounded-md w-full h-12 text-right" placeholder="البريد الإلكتروني" name="from_email" />
                        <input className=" outline-none focus:border-main text-main border-2 pr-2 rounded-md w-full h-12 text-right" placeholder="رقم الجوال" name="phone" />
                        <div className="flex flex-col items-end border-2 w-full text-end pr-4 rounded-lg p-2">
                            <div className='flex items-center gap-4'>
                                <label className="text-main font-bold mb-2"> طلب صيانة </label>
                                <input className='h-4 w-4 text-end bg-red-700 border-red-600 text-red-700' type="checkbox" value="طلب صيانة" name="maintenance_request" checked={isRadioSelected} onChange={() => setIsRadioSelected(!isRadioSelected)} />
                            </div>
                            {isRadioSelected && (
                                <div>
                                    <input required placeholder="الرجاء كتابة رقم الجمعية" name="gameai" className="text-end rounded-md outline-none border-2 focus:border-main p-2 mt-1" />
                                </div>
                            )}
                            <div className='flex items-center gap-4 '>
                                <label className="text-main font-bold mb-2"> إتحاد ملاك </label>
                                <input className='h-4 w-4 text-end bg-red-700 border-red-600 text-red-700' type="checkbox" value="إتحاد ملاك" name="ethad_mollak" checked={isRadioSelected2} onChange={() => setIsRadioSelected2(!isRadioSelected2)} />
                            </div>

                            {isRadioSelected2 && (
                                <div className='mr-8'>
                                    <div className="flex items-center gap-2">
                                        <label className=" text-sm font-medium text-main ">طلب جديد</label>
                                        <input type="radio" value="طلب جديد" name="new_order" className="w-4 h-4 " />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <label className=" text-sm font-medium text-main ">طلب قـائم</label>
                                        <input type="radio" value="طلب قائم" name="old_order" className="w-4 h-4 " />
                                    </div>

                                </div>
                            )}

                            <div className='flex items-center gap-4 '>
                                <label className="text-main font-bold mb-2"> شكاوى / إقتراحات </label>
                                <input className='h-4 w-4 text-end bg-red-700 border-red-600 text-red-700' type="checkbox" value="option" checked={isRadioSelected3} onChange={() => setIsRadioSelected3(!isRadioSelected3)} />
                            </div>
                            {isRadioSelected3 && (

                                <textarea className=" outline-none focus:border-main text-main border-2  pr-2 pt-2 rounded-md w-full h-36 text-right" placeholder="الرجاء كتابة الإقتراح / الشكوى" name="message" ></textarea>

                            )}

                        </div>
                        <button className="bg-main text-white rounded-md py-1 px-8 w-fit" type="submit" value="Send" >إرسال</button>
                    </form>
                </div>
                <hr className="my-10" />
            </div>
        </section>
    );
}

export default ContactUs;