import hero01 from '../images/hero01.jpeg'
import hero02 from '../images/hero02.jpeg'

function Here() {
    return (
        <section id='Hero'>
            <div className='flex flex-col '>
                <div className='relative'>
                    <div className='bg-blue-500 absolute rounded-full opacity-30 h-[13rem] md:h-[30rem] w-[13rem] md:w-[30rem] blur-3xl  right-0 top-0 -z-10'></div>
                </div>
                <div className='flex justify-between md:mx-[60px] mx-2 mt-4 md:mt-24'>
                    <img alt="img01" className='rounded-2xl h-[7.3rem] md:h-[22.5rem] w-[13rem] md:w-[40rem]' src={hero01} />

                    <div className='flex items-center justify-center flex-col mr-6 md:mr-14'>

                        <h1 className='text-3xl md:text-8xl text-main font-black'>شركة <span className='text-white'>أديرا</span></h1>
                        <h1 className='text-md md:text-3xl text-main'>لخدمات الأعمال</h1>
                    </div>
                </div>

                <div className='flex justify-between md:mx-[60px] mx-2 mt-4 md:mt-10'>
                    <div className='flex items-center justify-center flex-col ml-8 md:ml-28 relative'>
                        <div className='animate-pulse bg-red-600 absolute rounded-full h-[2rem] md:h-[3rem] w-[2rem] md:w-[3rem] blur-md md:blur-lg right-0 top-16 md:top-52 -z-10'></div>
                        <h1 className='text-3xl md:text-8xl text-main font-black'>وجدت</h1>
                        <h1 className='text-md md:text-3xl text-main '>...لتصنع الفرق</h1>
                    </div>
                    <img alt="img01" className='rounded-2xl h-[7.3rem] md:h-[22.5rem] w-[13rem] md:w-[40rem]' src={hero02} />
                </div>

                <hr className='my-10' />
            </div>
        </section>
    );
}

export default Here