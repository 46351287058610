import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import { useState } from "react";
import { OurProjectsData } from "./Data";


function OurProjects() {

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? OurProjectsData.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === OurProjectsData.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <div className=' flex   justify-center'>
                <h1 className=" text-white bg-gradient-to-bl from-main from-70% rounded-lg px-6 py-2 font-black text-4xl ">مشاريعنا</h1>
            </div>
            <h1 className="text-center text-main my-4">قمنا بفضل الله، بتقديم أفضل الحلول العقارية، لمجموعة قيمة من العملاء نعتز ونفخر بتقديم خدماتنا الإحترافية لهم</h1>

            <div className=' h-full w-full m-auto pt-4 px-4 relative group'>
                <div className=" flex flex-col items-center relative">
                    <img className="w-full h-[35rem] rounded-xl" src={OurProjectsData[currentIndex].image} />
                    <h1 className="absolute bg-black bg-opacity-25 w-fit rounded-lg px-6 left-0  right-0 mx-auto bottom-36 text-center text-3xl font-bold text-white  ">{OurProjectsData[currentIndex].title}</h1>
                    <p className="absolute bg-black bg-opacity-25 w-fit rounded-lg px-6 left-0 right-0 mx-auto bottom-10 text-center text-white">{OurProjectsData[currentIndex].text}</p>
                    {/* Left Arrow */}
                    <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                        <BsChevronCompactLeft onClick={prevSlide} size={30} />
                    </div>

                    {/* Right Arrow */}
                    <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                        <BsChevronCompactRight onClick={nextSlide} size={30} />
                    </div>
                </div>
                <div className='flex top-4 justify-center py-2'>
                    {OurProjectsData.map((slide, slideIndex) => (
                        <div
                            key={slideIndex}
                            onClick={() => goToSlide(slideIndex)}
                            className='text-2xl cursor-pointer'
                        >
                            <RxDotFilled />
                        </div>
                    ))}
                </div>
            </div>
            <hr className="my-10" />
        </>
    );
}

export default OurProjects;