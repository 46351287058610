import Navbar from "./components/Navbar";
import Here from "./components/Hero";
import AboutUs from "./components/AboutUs";
import WhySelectingUs from "./components/WhySelectingUs";
import Services from "./components/Services";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import OurProjects from "./components/OurProjects";
import RadioGroup from "./components/RadioGroup";

function App() {
  return (
    <div>
      <Navbar />
      <Here />
      <AboutUs />
      <WhySelectingUs />
      <Services />
      <OurProjects />
      <ContactUs />
      <Footer />
    </div>

  );
}

export default App;