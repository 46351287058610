import linkedin from '../icons/linkedin.png'

import logo from '../images/logo.jpg'
import { footerSocialMediaData } from './Data';



function Footer() {
    return (
        <footer className='bg-main h-full  w-full'>

            <div className='flex flex-row justify-between p-10'>
                {/* المرفقات */}
                <div className='flex flex-col justify-center items-center'>
                    <h1 className='text-2xl font-bold text-white mb-2'>المرفقات</h1>
                    <button className='w-full rounded-2xl py-1 px-4 bg-white text-main mb-2'>عرض السجل التجاري</button>
                    <button className='w-full rounded-2xl py-1 px-4 bg-main border-2 border-white  text-white'>عرض شهادة التسجيل الضريبي</button>
                </div>
                {/* الصفحات وحسابات التواصل */}
                <div className='flex flex-col gap-4 items-center justify-center'>
                    <div className='flex justify-center text-white gap-10'>
                        <a href='#contactUs'>تواصل معنا</a>
                        <a href='#Services'>خدماتنا</a>
                        <a href='#AboutUs'>من نحن؟</a>
                        <a href='/'>الرئيسية</a>
                    </div>
                    <div className='flex justify-center gap-4 items-center '>
                        {footerSocialMediaData.map((item => (
                            <a href={item.link}>
                                <img className='h-12 w-12' src={item.icon}
                                />
                            </a>
                        )))}
                    </div>
                </div>
                {/* الجوال والإيميل */}
                <div className='flex flex-col text-white items-center justify-center'>
                    <h1 className='text-2xl font-bold mb-2'>أديرا لخدمات الأعمال</h1>
                    <p>+966 55 336 6865</p>
                    <p>info@adera.com.sa</p>
                </div>
            </div>
            <hr className='my-5 ' />
            <h1 className='text-center text-white'>جميع الحقوق محفوظة لشركة أديرا لخدمات الأعمال © 2023</h1>
        </footer>

    );
}
export default Footer;