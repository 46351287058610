import molak from '../images/mullakIcon.png'
import mullak from '../icons/mullakSvg.svg'
import EjarSvg from '../icons/EjarSvg.svg'
import itehad_mullak from '../icons/itehad_mullak.png'
import { ServicesDataEjar, ServicesDataMullak } from './Data'

import { useState } from 'react'

function Services() {
    const [isOpen, setIsOpen] = useState(false);

    const makeItFalse = () => {
        setIsOpen(false);
    };
    const makeItTrue = () => {
        setIsOpen(true);
    };

    return (
        <>
            <section id='Services'>
                <div className='relative'>
                    <div className='bg-blue-500 fixed rounded-full opacity-20 h-[13rem] md:h-[30rem] w-[13rem] md:w-[30rem] blur-3xl  right-0 top-0 -z-10'></div>
                </div>

                <div className='relative'>
                    <div className='bg-red-500 fixed rounded-full opacity-20 h-[13rem] md:h-[30rem] w-[13rem] md:w-[30rem] blur-3xl  left-0 top-56 -z-10'></div>
                </div>

                <div className=' flex   justify-center'>
                    <h1 className=" text-white bg-gradient-to-bl from-main from-70% rounded-lg px-6 py-2 font-black text-4xl ">خدماتنا</h1>
                </div>
                <h1 className="text-center text-main my-4">تساعد خدماتنا القيمة، والتي نقدمها لشركاء نجاحنا بكل شغف وحب، في رفع  معايير الجودة وترسيخ مبادئ الثقة والتعاون بين المؤجر والمستأجر</h1>

                <div className="flex justify-center gap-4 text-white">

                    <button onClick={makeItFalse} className=" rounded-md bg-main border-2 border-main w-72 ">
                        <div className="group flex justify-between items-center ">
                            <h1 className="text-2xl font-bold ml-auto mr-auto">إيجار</h1>
                            <img src={EjarSvg} className="bg-white  w-28 h-12 rounded-tl-full rounded-r-[90rem] group-hover:rounded-lg  px-8" />
                        </div>
                    </button>
                    <button onClick={makeItTrue} className="rounded-md bg-main border-2 border-main w-72 ">
                        <div className="group flex justify-between items-center ">
                            <img src={mullak} className="bg-white w-28 h-12 rounded-tr-full rounded-l-[90rem] group-hover:rounded-lg px-8" />
                            <h1 className="text-2xl font-bold ml-auto mr-auto">ملاك</h1>
                        </div>
                    </button>
                </div>

                {/* Mullak */}
                <div className={`${!isOpen ? 'hidden' : 'block'}`}>
                    <div className='flex flex-wrap gap-10 items-center justify-center mt-10 '>
                        {ServicesDataMullak.map((item) => (
                            <div className='group hover:bg-red-50 border-2 border-main h-48 w-72 p-4 rounded-xl gap-3 flex flex-col items-center text-main'>
                                <img className='animate-pulse h-16 w-16 ' src={item.icon} />
                                <h1 className='group-hover:animate-bounce font-bold text-2xl text-center'>{item.header}</h1>
                                <p>{item.explain}</p>
                            </div>

                        ))}
                    </div>
                </div>

                {/* Ejar */}
                <div className={`${isOpen ? 'hidden' : 'block'}`}>
                    <div className='flex flex-wrap gap-10 items-center justify-center mt-10 '>
                        {ServicesDataEjar.map((item) => (
                            <div className='group hover:bg-red-50 border-2 border-main h-48 w-72 p-4 rounded-xl gap-3 flex flex-col items-center text-main'>
                                <img className='group-hover:animate-bounce h-16 w-16 ' src={item.icon} />
                                <h1 className='group-hover:animate-pulse font-bold text-2xl text-center'>{item.header}</h1>
                                <p>{item.explain}</p>
                            </div>

                        ))}
                    </div>
                </div>

                <hr className='my-10' />
            </section>
        </>
    );
}

export default Services;